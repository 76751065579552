@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar-track {
    background-color: #fafafa;
  }
  .scrollbar::-webkit-scrollbar {
    width: 3px;
    background: #fafafa;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: #c2c2c2;
  }
}
