* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Asap;
}

@media only screen and (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media only screen and (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

input:focus {
  outline: none;
}

abbr[title] {
  text-decoration: none;
}

#nprogress .spinner {
  display: none !important;
}

.leaflet-pane {
  z-index: 0 !important;
}
